<template>
    <setting-layout :title="$t('pages.user.setting.sessions.title')">
        <v-container class="pr-2 pl-2">
            <v-row>
                <v-col cols="4" class="table-head">
                    {{ $t('pages.user.setting.sessions.device') }}
                </v-col>
                <v-col cols="4" class="table-head">
                    {{ $t('pages.user.setting.sessions.browser') }}
                </v-col>
                <v-col cols="4" class="table-head">
                    {{ $t('pages.user.setting.sessions.date') }}
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in newestLastTenSessions" :key="index">
                <v-col cols="4" class="table-body">
                    {{ item.device }}
                </v-col>
                <v-col cols="4" class="table-body">
                    {{ item.browser }}
                </v-col>
                <v-col cols="4" class="table-body">
                    {{ moment(item.created_at).format('YYYY/MM/DD') }}
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-16">
                <span>{{ $t('pages.user.setting.sessions.something_went_wrong') }}&nbsp; </span><router-link :to="{name: 'user.setting.password'}" class="link-change-password ml-2">{{ $t('pages.user.setting.sessions.change_your_password') }}</router-link>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import {mapActions} from "vuex";

export default {
    name: "Sessions",
    data: ()=>({
        sessions: []
    }),
    computed: {
      newestLastTenSessions () {
          return this.sessions.sort((a, b) =>  new Date(b.created_at) - new Date(a.created_at)).slice(0,10)
      }
    },
    methods: {
        ...mapActions(['setSessions'])
    },
    mounted() {
        this.setSessions().then(res => this.sessions = res)
    },
    components: {SettingLayout}
}
</script>

<style scoped>
.table-head {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9em;
}
.table-body{
    text-align: center;
    font-size: 0.9em;
}
.link-change-password{
    color: #FE4811
}
</style>
